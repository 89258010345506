import React from 'react';
import Layout from '../components/layout';

const MaterialEvents = () => {
  return (
    <Layout pageTitle="Material Events" title="MRC Agrotech Ltd - Management">
      <section className="row justify-content-center" data-sal="zoom-in" data-sal-easing="ease-out">
        <div className="col-lg-6">
          <p>Following people are authorized by the board to determine the materiality of event and information that needs to be intimated to the stock exchange.</p>
          <p><strong>Mr. Uttam Kumar Singh,</strong><br />
            Director,<br />
            Contact No.: <a className="link-secondary" href="tel:+912240156765">+9122 40156765</a><br />
            Email ID: <a className="link-secondary" href="mailto:info@mrcagro.com">info@mrcagro.com</a></p>

          <p><strong>Mr. Rahul Mathur,</strong><br />
            Company Secretary and Compliance Officer.<br />
            Contact No.: <a className="link-secondary" href="tel:+917045000048">+91 7045000048</a><br />
            Email ID:  <a className="link-secondary" href="mailto:mrcagrotech@gmail.com">mrcagrotech@gmail.com</a></p>
        </div>
      </section>
    </Layout>
  );
};

export default MaterialEvents;
